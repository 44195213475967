@import 'functions';

.-size-xl-important {
  font-size: rem(24px) !important;
}

.-size-m-important {
  font-size: rem(16px) !important;
}

.-size-ms {
  font-size: rem(13px);
  line-height: 1.5;
}
