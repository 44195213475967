/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

@import '../node_modules/@bosch/frontend.kit-npm/dist/frontend-kit.css';

@import 'styles/typography';
@import 'styles/colors';

html {
  height: 100%;
}

/* You can add global styles to this file, and also import other style files */
:focus {
  outline: none !important;
}

:root {
  --amplify-font-family: 'boschsans';
  --amplify-primary-color: rgb(18, 99, 152);
  --amplify-primary-tint: #008ecf;
  --amplify-primary-shade: #008ecf;
}

body {
  height: 100% !important;
  position: relative;
  overscroll-behavior: none;
}

app-root {
  display: block;
  height: 100%;
}

.blur {
  filter: blur(8px);
  background-color: var(--bosch-gray-80);
}

.list-strong {
  padding: 0.5rem 0 1rem 0;

  li {
    margin: 0;
    font-weight: 700;
  }
}

.-size-1-5xl {
  --font-size: 1.75rem;
  --line-height: 1.4;
  font-size: 1.75rem;
  line-height: 1.4;
}

.video-iframe-container {
  position: relative;
  padding-bottom: 71.25%;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
